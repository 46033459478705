<template>
    <div class="talent-pool">
        <table-wrapper :table-type="'talentPool'"></table-wrapper>
    </div>

</template>


<script>
    import TableWrapper from './layout/tableWrapper.vue';
    import tableScrollTo from '#/js/mixins/tableScrollTo.js';
    export default {
        name: "TalentPool",
        components: {
            TableWrapper
        },
        mixins: [tableScrollTo],
        data() {
            return {}
        },
        activated() {
            this.tableScrollTo();
        },
        mounted() {}
    }
</script>

<style lang="scss" scoped>
.talent-pool{
    height: 100%;
    overflow-y: auto;
}
</style>
